@font-face {
  font-family: Audiowide;
  src: url('../assets/fonts/Audiowide-Regular.ttf');
}

@font-face {
  font-family: Oswald;
  src: url('../assets/fonts/Oswald.ttf');
}

@font-face {
  font-family: Roboto;
  src: url('../assets/fonts/Roboto-Regular.ttf');
}
